import React from "react"

const SpeakerSection = ({ post }) => (
  <div className="speaker">
    <div className="image-wrapper">
      <img src={post.frontmatter.image.publicURL} alt={post.frontmatter.name}/>
    </div>
    <p>{post.frontmatter.name}</p>
  </div>
)

export default SpeakerSection
